import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Spacer from "../components/Spacer";

// markup
const HistoriaPage = () => {
  return (
    <>
      <Header></Header>
      <div className="Index-page-content">
        <div className="row sqs-row">
          <div className="col sqs-col-2 span-2">
            <div
              className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
              data-block-type="21"
              id="block-yui_3_17_2_2_1453931017574_18182"
            >
              <div className="sqs-block-content">&nbsp;</div>
            </div>
          </div>
          <div className="col sqs-col-8 span-8">
            <div
              className="sqs-block html-block sqs-block-html"
              data-block-type="2"
              id="block-yui_3_17_2_10_1450195597281_33383"
            >
              <h1 style={{ textAlign: "center" }}>ADN</h1>

              <div className="sqs-block-content">
                <h2 className="text-align-center">Nuestra historia</h2>
                <p>
                  Desde el año 2005 el pastor Michel y su esposa Gaby comenzaron
                  a orar cuando eran tan solo amigos para que Dios estableciera
                  una iglesia sana en la zona de Weston que impactara el Sur de
                  la Florida con el evangelio. Desde entonces han estado
                  preparándose para comenzar lo que hoy es Iglesia Gracia Sobre
                  Gracia.
                </p>
                <p>
                  Después de terminar su Maestría en Divinidad en el seminario
                  de Bethlehem en Minnesota, el pastor Michel regresa con su
                  familia a Weston para plantar GSG. Comienzan a reunirse en
                  casa en Agosto del 2016 y desde entonces han visto la gracia
                  de Dios al traer aquellas familias que hoy día son parte
                  esencial para el establecimiento de GSG.
                </p>
                <p>
                  Estamos confiados en que GSG es la obra de Dios para proclamar
                  Su gloria en Cristo y por medio del Espíritu Santo para que
                  otros vean que en Cristo “hemos recibido gracia sobre gracia”
                  Juan 1:16.
                </p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
          <div className="col sqs-col-2 span-2">
            <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1">
              <div className="sqs-block-content">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default HistoriaPage;
