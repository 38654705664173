import React from "React";

const Spacer = (props) => {
  return (
    <div
      className={`sqs-block spacer-block sqs-block-spacer sized vsize-1 ${props.hideMobile ? 'spacer-block-hide-mobile' : ''}`}
      data-block-type="21"
      id="block-yui_3_17_2_6_1459966349478_236570"
    >
      <div className="sqs-block-content">&nbsp;</div>
    </div>

  );
};

export default Spacer;
